






























import { Component, Prop } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { WorkOrderFormDesignEntityModel, WorkOrderFormDesignQueryModel } from '@/entity-model/work-order-form-design-entity';
import WorkOrderFormDesignService from '@/service/work-order-form-design';
import { FormControlOptionModel } from '@/model/form-control';

@Component
export default class WorkOrderFormDesginFieldComponent extends TableDialogFormComponent<WorkOrderFormDesignEntityModel, WorkOrderFormDesignQueryModel> {
    WorkOrderFormDesignEntityModel = WorkOrderFormDesignEntityModel;

    @Prop()
    formId: string;

    created() {
        this.formId = this.$route.params.id;

        this.initTable({
            service: new WorkOrderFormDesignService(this.formId),
            queryModel: new WorkOrderFormDesignQueryModel(),
            tableColumns: WorkOrderFormDesignEntityModel.getTableColumns()
        });

        this.getList();
    }

    add() {
        let newData:WorkOrderFormDesignEntityModel = new WorkOrderFormDesignEntityModel();

        newData.index = this.listData.length.toString();

        this.addClick(newData);
    }

    formChange(model: FormControlOptionModel) {
        if (model.key === 'dataType') {
            switch (this.formDialog.jtlForm.formModel.dataType) {
                case 'DROPDOWN':
                case 'TreeDROPDOWN':
                case 'MULTISELECT':
                    this.formDialog.jtlForm.formControlList[3].invisible = false;
                    break;
                default:
                    this.formDialog.jtlForm.formControlList[3].invisible = true;
                    break;
            }
        }
    }
}
